<template>
  <div class="item-details">
    <v-card :loading="loading" class="mb-3">
      <v-card-title>Detail Item</v-card-title>

      <v-divider></v-divider>

      <v-card-text v-if="loading" class="text-center"> Loading... </v-card-text>

      <v-card-text v-else>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <template v-for="i in list">
                <tr :key="i.title">
                  <td class="text-left">
                    <div>{{ i.title }}</div>
                    <div v-if="i.subtitle">{{ i.subtitle }}</div>
                  </td>
                  <td class="text-right" v-text="item[i.value]"></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card :loading="loading" class="mb-3">
      <v-card-title>Status Stock</v-card-title>

      <v-divider></v-divider>

      <v-card-text v-if="loading" class="text-center"> Loading... </v-card-text>

      <v-card-text v-else>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="l in item.locations" :key="l.code">
                <td v-html="l.name" class="text-left"></td>
                <td class="text-right">
                  <v-chip
                    :color="l.qty > 0 ? 'light-blue' : 'red'"
                    class="mr-2"
                    small
                    >{{ l.qty }} {{ item.units }}</v-chip
                  >
                </td>
              </tr>
              <tr class="footer">
                <td class="text-left">Total</td>
                <td class="text-right">
                  <v-chip
                    :color="item.qty > 0 ? 'light-blue' : 'red'"
                    class="mr-2"
                    small
                    >{{ item.qty }} {{ item.units }}</v-chip
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card :loading="loading" class="mb-3">
      <v-card-title>Harga Jual Terakhir</v-card-title>

      <v-divider></v-divider>

      <div class="pa-2">
        <customers-autocomplete
          :value.sync="lastSalePriceCustomer"
        ></customers-autocomplete>
      </div>

      <v-divider></v-divider>

      <v-card-text class="text-center" v-if="loading"> Loading... </v-card-text>

      <v-card-text v-else>
        <h2 v-text="item.last_sale_price"></h2>
      </v-card-text>
    </v-card>

    <v-card :loading="loading" class="mb-3">
      <v-card-title>Harga Jual</v-card-title>

      <v-divider></v-divider>

      <v-card-text v-if="loading" class="text-center"> Loading... </v-card-text>

      <v-card-text v-else>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="p in item.prices" :key="p.id">
                <td v-html="p.sales_type" class="text-left"></td>
                <td class="text-right" v-text="p.price"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card :loading="loading" class="mb-3">
      <v-card-title>Harga Beli Terakhir</v-card-title>

      <v-divider></v-divider>

      <v-card-text class="text-center" v-if="loading"> Loading... </v-card-text>

      <v-card-text v-else>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>{{ item.last_purchase_date }}</td>
                <td class="text-right">{{ item.last_purchase_price }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card v-if="!isSalesman" :loading="loading">
      <v-card-title>Data Pembelian</v-card-title>

      <v-divider></v-divider>

      <v-card-text class="text-center" v-if="loading"> Loading... </v-card-text>

      <v-card-text v-else>
        <h4
          v-if="item.purchase_data !== undefined && !item.purchase_data.length"
          class="text-center"
        >
          Belum ada data pembelian
        </h4>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Supplier</th>
                <th class="text-left">Tanggal</th>
                <th class="text-right">Nilai</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="data in item.purchase_data">
                <tr :key="data.supplier_id">
                  <td
                    class="text-left"
                    colspan="3"
                    v-text="data.supp_name"
                  ></td>
                </tr>
                <tr :key="data.supplier_id">
                  <td class="text-left" v-text="sql2date(data.tran_date)"></td>
                  <td
                    class="text-right"
                    colspan="2"
                    v-text="data.unit_price"
                  ></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CustomersAutocomplete from "@/components/CustomersAutocomplete.vue";

export default {
  name: "ItemDetails",

  components: {
    CustomersAutocomplete,
  },

  data() {
    return {
      item: { purchase_data: [] },

      isSalesman: this.getSalesmanStatus(),

      lastSalePriceCustomer: "",

      loading: false,

      stock_id: null,
    };
  },

  computed: {
    list() {
      let list = [
        { title: "Kode", value: "stock_id" },
        { title: "Nama", value: "description" },
        { title: "Kategori", value: "category_name" },
        { title: "Satuan", value: "units" },
      ];

      if (this.item.tax_type_id == 1) {
        list.push({ title: "Standard Cost", value: "material_cost" });
      } else if (this.item.tax_type_id == 2) {
        list.push({
          title: "Standard Cost",
          subtitle: "+ Pajak",
          value: "material_cost",
        });
      }

      return list;
    },
  },

  watch: {
    lastSalePriceCustomer() {
      this.getLastSalePrice();
    },
  },

  mounted() {
    this.stock_id = this.$route.params.stock_id;

    this.getItem();
  },

  methods: {
    getItem() {
      this.loading = true;

      this.$http
        .get("/inventory/items", {
          params: { stock_id: this.stock_id },
        })
        .then((result) => {
          this.item = result.data;
          this.loading = false;
        });
    },

    getLastSalePrice() {
      this.$http
        .get(
          "/customers/" + this.lastSalePriceCustomer + "/items/last-sale-price",
          {
            params: {
              stock_id: this.stock_id,
            },
          }
        )
        .then(({ data }) => {
          this.item.last_sale_price = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer td {
  background-color: #262626;
}

table tr td {
  word-break: break-all;
}
</style>