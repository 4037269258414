<template>
  <v-autocomplete
    v-model="customer"
    :items="customers"
    :loading="loading"
    :search-input.sync="search"
    item-text="br_name"
    item-value="branch_code"
    label="Customer"
    hide-details
    hide-no-data
    outlined
  ></v-autocomplete>
</template>

<script>
export default {
  name: "CustomersAutocomplete",

  props: {
    value: String,
    useAll: Boolean,
  },

  data() {
    let customers = [];

    if (this.useAll) {
      customers[0] = { branch_code: "0", br_name: "Semua" };
    }

    return {
      customers: customers,
      loading: false,
      search: null,
    };
  },

  computed: {
    customer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },

  watch: {
    search(val) {
      this.loading = true;

      this.$http
        .get("/customers", {
          params: {
            search: val,
          },
        })
        .then(({ data }) => {
          if (this.useAll) {
            this.customers = [{ branch_code: "0", br_name: "Semua" }];
          }
          this.customers = this.customers.concat(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>